/* styles.css */
@media print {
    
    .printTable {
      font-size: 8px; /* Smaller font size for printing */
    }
    .printTable th,
    .printTable td {
      padding: 2px; /* Smaller padding for printing */
    }
    body {
      -webkit-print-color-adjust: exact;
      padding: 20mm; /* Padding around the content */
    }
    @page {
      size: auto;
      margin: 5mm; /* Custom margin */
    }
    @page {
      margin: 0;
      margin: 5mm; /* Custom margin */
    }
    @page :first {
      margin-top: 0;
    }
    header, footer {
      display: none;
    }
  }
  