@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(24, 144, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
  }
}

.pulse-button {
  animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  animation: none;
}

.notice-red {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.notice-green {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}

.notice-orange {
  background-color: #faad14 !important;
  border-color: #faad14 !important;
}

.notice-default {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.notice-red,
.notice-green,
.notice-orange,
.notice-default {
  color: white !important;
}
